import React from 'react';
import Layout from './../components/layout';
import { InternalLinkButton } from '../components/button';
import { GridSpacing } from '../components/grid';

const NotFoundPage = () => {

	return (
		<Layout>
			<div className='page-404'>
				<GridSpacing>
					<div className='w-full h-screen flex items-center justify-center flex-col'>
						<h1 className='page-404__title'>Page not found</h1>
						<InternalLinkButton to='/'>Get back home</InternalLinkButton>
					</div>
				</GridSpacing>
			</div>
		</Layout>
	);
};

export default NotFoundPage;
